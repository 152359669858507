import React from 'react';
import { useTranslation } from 'react-i18next';
import { Form, FormInstance, Input, Button } from 'antd';

export type ResetBody = {
  password: string;
  rpassword: string;
};

export interface ResetFormProps {
  formRef: FormInstance;
  onSubmit?: (values: ResetBody) => Promise<void>;
  loading?: boolean;
}

const ResetForm: React.FC<ResetFormProps> = ({ formRef, onSubmit, loading }) => {
  const { t } = useTranslation();

  return (
    <>
      <h3>{t('forgot.newPass')}</h3>
      <Form
        form={formRef}
        name="forgot-form"
        layout="vertical"
        autoComplete="off"
        onFinish={onSubmit}
      >
        <Form.Item
          label={t('login.password')}
          name="password"
          rules={[{ required: true, type: 'string', whitespace: true }]}
        >
          <Input.Password />
        </Form.Item>

        <Form.Item
          label={t('users.passwordConfirmation')}
          name="rpassword"
          rules={[{ required: true, type: 'string', whitespace: true }]}
        >
          <Input.Password />
        </Form.Item>

        <Form.Item>
          <Button
            type="primary"
            style={{ width: '100%' }}
            htmlType="submit"
            size="large"
            loading={loading}
          >
            {t('forgot.changePass')}
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default ResetForm;

import { ApisauceInstance, create } from 'apisauce';
import { notification } from 'antd';

import { HttpError, HttpSuccessGetAll, HttpLogin } from '@/interfaces/api.interface';
import { HttpForgot } from '@/interfaces/forgot.interface';
import { HttpVerify } from '@/interfaces/verify.interface';

import { store } from '@/redux/store';
import { User, UserCreation } from '@/interfaces/user.interface';
import { EmailLogs } from '@/interfaces/emailLogs.interface';
import { ResetBody } from '@/components/forms/ResetForm';
import { Label, RawLabel } from '@/interfaces/label.interface';
import { ApiKey, RawApiKey } from '@/interfaces/apiKey.interface';
import { Roles, RawRoles } from '@/interfaces/roles.interface';
import {
  Configuration,
  RawConfiguration,
  PatchConfig,
} from '@/interfaces/configuration.interface';
import i18n from '@/i18n';
import { File as FileI } from '@/interfaces/files.interface';
import { Catalog, RawCatalog } from '@/interfaces/catalogs.interface';
import { MetaCategory, RawMetaCategory } from '@/interfaces/metaCategories.interface';
import { Agent, RawAgent } from '@/interfaces/agent.interface';
import { Currency, RawCurrency } from '@/interfaces/currency.interface';
import { Unit, RawUnit } from '@/interfaces/unit.interface';
import { PropertyField, RawPropertyField } from '@/interfaces/propertyField.interface';
import { Property, RawProperty } from '@/interfaces/property.interface';
import { Page, RawPage } from '@/interfaces/page.interface';
import { Type, RawType } from '@/interfaces/type.interface';

const getToken = () => store.getState().auth.token;
const getApiKey = () => import.meta.env.VITE_APP_API_KEY;

export type AuthBody = {
  email: string;
  password: string;
};

export class API {
  private api: ApisauceInstance;
  constructor(baseURL = import.meta.env.VITE_APP_API_URL) {
    this.api = create({
      baseURL,
    });
    // Intercept all 401 errors and logout user
    this.api.addResponseTransform((response) => {
      if (response.status === 401) {
        store.dispatch({ type: 'auth/logout' });
        notification.warning({
          message: i18n.t('alerts.sessionExpired'),
        });
      }
    });
  }
  public async login({ email, password }: AuthBody) {
    return this.api.post<HttpLogin, HttpError>('/authentication', {
      email,
      password,
      strategy: 'local',
    });
  }
  public async verifyUser(token: string) {
    return this.api.get<HttpVerify, HttpError>(
      '/verify/' + token,
      {},
      {
        headers: {
          'x-access-token': getApiKey(),
        },
      },
    );
  }
  public async requestForgot(email: string) {
    return this.api.post<HttpForgot, HttpError>(
      '/forgot',
      {
        email,
      },
      {
        headers: {
          'x-access-token': getApiKey(),
        },
      },
    );
  }
  public async resetPassword(token: string | undefined, data: ResetBody) {
    return this.api.patch<HttpForgot, HttpError>('/forgot/' + token, data, {
      headers: {
        'x-access-token': getApiKey(),
      },
    });
  }
  // HTTP request to create new user with full_name, email, password
  public async createUser(values: UserCreation | User) {
    return this.api.post<HttpLogin, HttpError>('/users', values, {
      headers: {
        authorization: `Bearer ${getToken()}`,
      },
    });
  }
  // HTTP request to get user data
  public async getUser(user_id: string) {
    return this.api.get<User, HttpError>(
      '/users/' + user_id,
      {},
      {
        headers: {
          authorization: `Bearer ${getToken()}`,
        },
      },
    );
  }
  // HTTP request to update user data
  public async updateUser(user_id: string, user: User) {
    return this.api.patch<User, HttpError>('/users/' + user_id, user, {
      headers: {
        authorization: `Bearer ${getToken()}`,
      },
    });
  }
  // HTTP request to delete user
  public async deleteUser(user_id: string) {
    return this.api.delete<null, HttpError>(
      '/users/' + user_id,
      {},
      {
        headers: {
          authorization: `Bearer ${getToken()}`,
        },
      },
    );
  }
  // HTTP request to get all users
  public async getUsers(queries?: Record<string, string>) {
    const result = await this.api.get<HttpSuccessGetAll<User>>(
      '/users?' + new URLSearchParams(queries).toString(),
      {},
      {
        headers: {
          authorization: `Bearer ${getToken()}`,
        },
      },
    );
    if (result.problem) throw new Error(result.problem);
    return result.data;
  }
  // HTTP request to get all email logs
  public async getEmailLogs(queries?: Record<string, string>) {
    const result = await this.api.get<HttpSuccessGetAll<EmailLogs>>(
      '/email-logs?' + new URLSearchParams(queries).toString(),
      {},
      {
        headers: {
          authorization: `Bearer ${getToken()}`,
        },
      },
    );
    if (result.problem) throw new Error(result.problem);
    return result.data;
  }
  // HTTP request to get all labels
  public async getLabels(queries?: Record<string, string>) {
    const result = await this.api.get<HttpSuccessGetAll<RawLabel>>(
      '/labels?' + new URLSearchParams(queries).toString(),
      {},
      {
        headers: {
          authorization: `Bearer ${getToken()}`,
        },
      },
    );
    if (result.problem) throw new Error(result.problem);
    return result.data;
  }
  // update label
  public async updateLabel(label_id: string, label: Label) {
    return this.api.patch<Label, HttpError>('/labels/' + label_id, label, {
      headers: {
        authorization: `Bearer ${getToken()}`,
      },
    });
  }
  // delete label
  public async deleteLabel(label_id: string) {
    return this.api.delete<RawLabel, HttpError>(
      '/labels/' + label_id,
      {},
      {
        headers: {
          authorization: `Bearer ${getToken()}`,
        },
      },
    );
  }
  // create label
  public async createLabel(values: Label) {
    return this.api.post<RawLabel, HttpError>('/labels', values, {
      headers: {
        authorization: `Bearer ${getToken()}`,
      },
    });
  }
  // Files section
  public async getFiles(queries?: Record<string, string>) {
    const result = await this.api.get<HttpSuccessGetAll<FileI>>(
      '/multimedia?' + new URLSearchParams(queries).toString(),
      {},
      {
        headers: {
          authorization: `Bearer ${getToken()}`,
        },
      },
    );
    if (result.problem) throw new Error(result.problem);
    return result.data;
  }
  public async uploadFile(file: File) {
    const data = new FormData();
    data.append('file', file);
    return this.api.post<FileI, HttpError>('/multimedia', data, {
      headers: {
        authorization: `Bearer ${getToken()}`,
        'Content-Type': 'multipart/form-data',
      },
    });
  }
  public async deleteFile(file_id: string) {
    return this.api.delete<RawLabel, HttpError>(
      '/multimedia/' + file_id,
      {},
      {
        headers: {
          authorization: `Bearer ${getToken()}`,
        },
      },
    );
  }
  // HTTP request to get all api keys
  public async getApiKeys(queries?: Record<string, string>) {
    const result = await this.api.get<HttpSuccessGetAll<RawApiKey>>(
      '/api-keys?' + new URLSearchParams(queries).toString(),
      {},
      {
        headers: {
          authorization: `Bearer ${getToken()}`,
        },
      },
    );
    if (result.problem) throw new Error(result.problem);
    return result.data;
  }
  // update api key
  public async updateApiKey(id: string, data: ApiKey) {
    return this.api.patch<ApiKey, HttpError>('/api-keys/' + id, data, {
      headers: {
        authorization: `Bearer ${getToken()}`,
      },
    });
  }
  // delete api key
  public async deleteApiKey(id: string) {
    return this.api.delete<RawApiKey, HttpError>(
      '/api-keys/' + id,
      {},
      {
        headers: {
          authorization: `Bearer ${getToken()}`,
        },
      },
    );
  }
  // create api key
  public async createApiKey(data: ApiKey) {
    return this.api.post<RawApiKey, HttpError>('/api-keys', data, {
      headers: {
        authorization: `Bearer ${getToken()}`,
      },
    });
  }
  // HTTP request to get all roles
  public async getRoles(queries?: Record<string, string>) {
    const result = await this.api.get<HttpSuccessGetAll<RawRoles>>(
      '/roles?' + new URLSearchParams(queries).toString(),
      {},
      {
        headers: {
          authorization: `Bearer ${getToken()}`,
        },
      },
    );
    if (result.problem) throw new Error(result.problem);
    return result.data;
  }
  // update rol
  public async updateRoles(id: string, data: Roles) {
    return this.api.patch<Roles, HttpError>('/roles/' + id, data, {
      headers: {
        authorization: `Bearer ${getToken()}`,
      },
    });
  }
  // delete rol
  public async deleteRoles(id: string) {
    return this.api.delete<RawRoles, HttpError>(
      '/roles/' + id,
      {},
      {
        headers: {
          authorization: `Bearer ${getToken()}`,
        },
      },
    );
  }
  // create rol
  public async createRoles(data: Roles) {
    return this.api.post<RawRoles, HttpError>('/roles', data, {
      headers: {
        authorization: `Bearer ${getToken()}`,
      },
    });
  }
  // HTTP request to get all configuration
  public async getConfigs(queries?: Record<string, string>) {
    const result = await this.api.get<HttpSuccessGetAll<RawConfiguration>>(
      '/configs?' + new URLSearchParams(queries).toString(),
      {},
      {
        headers: {
          authorization: `Bearer ${getToken()}`,
        },
      },
    );
    if (result.problem) throw new Error(result.problem);
    return result.data;
  }
  // update config
  public async updateConfig(id: string, data: PatchConfig) {
    return this.api.patch<Configuration, HttpError>('/configs/' + id, data, {
      headers: {
        authorization: `Bearer ${getToken()}`,
      },
    });
  }
  // delete config
  public async deleteConfig(id: string) {
    return this.api.delete<RawConfiguration, HttpError>(
      '/configs/' + id,
      {},
      {
        headers: {
          authorization: `Bearer ${getToken()}`,
        },
      },
    );
  }
  // create config
  public async createConfig(data: Configuration) {
    return this.api.post<RawConfiguration, HttpError>('/configs', data, {
      headers: {
        authorization: `Bearer ${getToken()}`,
      },
    });
  }
  // Catalogs category
  public async createCatalog(data: Catalog) {
    return this.api.post<RawRoles, HttpError>('/categories', data, {
      headers: {
        authorization: `Bearer ${getToken()}`,
      },
    });
  }
  // HTTP request to get all configuration
  public async getCatalogs(queries?: Record<string, string>) {
    const result = await this.api.get<HttpSuccessGetAll<RawCatalog>>(
      '/categories?' + new URLSearchParams(queries).toString(),
      {},
      {
        headers: {
          authorization: `Bearer ${getToken()}`,
        },
      },
    );
    if (result.problem) throw new Error(result.problem);
    return result.data;
  }
  public async getCatalog(id: string) {
    const result = await this.api.get<RawCatalog>(
      '/categories/' + id,
      {},
      {
        headers: {
          authorization: `Bearer ${getToken()}`,
        },
      },
    );
    if (result.problem) throw new Error(result.problem);
    return result.data;
  }
  // update config
  public async updateCatalog(id: string, data: Catalog) {
    return this.api.patch<Catalog, HttpError>('/categories/' + id, data, {
      headers: {
        authorization: `Bearer ${getToken()}`,
      },
    });
  }
  // delete config
  public async deleteCatalog(id: string) {
    return this.api.delete<RawCatalog, HttpError>(
      '/categories/' + id,
      {},
      {
        headers: {
          authorization: `Bearer ${getToken()}`,
        },
      },
    );
  }

  //meta-categories
  public async createMetaCategory(data: MetaCategory) {
    return this.api.post<RawRoles, HttpError>('/meta-categories', data, {
      headers: {
        authorization: `Bearer ${getToken()}`,
      },
    });
  }
  public async getMetaCategories(queries?: Record<string, string>) {
    const result = await this.api.get<HttpSuccessGetAll<RawMetaCategory>>(
      '/meta-categories?' + new URLSearchParams(queries).toString(),
      {},
      {
        headers: {
          authorization: `Bearer ${getToken()}`,
        },
      },
    );
    if (result.problem) throw new Error(result.problem);
    return result.data;
  }
  public async updateMetaCategory(
    id: string,
    data: MetaCategory | Record<any, any>,
    queries?: Record<string, string>,
  ) {
    return this.api.patch<MetaCategory, HttpError>(
      `/meta-categories/${id}?${new URLSearchParams(queries).toString()}`,
      data,
      {
        headers: {
          authorization: `Bearer ${getToken()}`,
        },
      },
    );
  }

  public async getAgents(queries?: Record<string, string>) {
    const result = await this.api.get<HttpSuccessGetAll<RawAgent>>(
      '/agents?' + new URLSearchParams(queries).toString(),
      {},
      {
        headers: {
          authorization: `Bearer ${getToken()}`,
        },
      },
    );
    if (result.problem) throw new Error(result.problem);
    return result.data;
  }
  public async getAgent(id: string) {
    const result = await this.api.get<RawAgent>(
      '/agents/' + id,
      {},
      {
        headers: {
          authorization: `Bearer ${getToken()}`,
        },
      },
    );
    if (result.problem) throw new Error(result.problem);
    return result.data;
  }
  public async createAgent(data: Agent) {
    return this.api.post<RawAgent, HttpError>('/agents', data, {
      headers: {
        authorization: `Bearer ${getToken()}`,
      },
    });
  }
  public async updateAgent(id: string, data: Agent) {
    return this.api.patch<Agent, HttpError>('/agents/' + id, data, {
      headers: {
        authorization: `Bearer ${getToken()}`,
      },
    });
  }
  public async deleteAgent(id: string) {
    return this.api.delete<RawAgent, HttpError>(
      '/agents/' + id,
      {},
      {
        headers: {
          authorization: `Bearer ${getToken()}`,
        },
      },
    );
  }
  // HTTP request to get all currencies
  public async getCurrencies(queries?: Record<string, string>) {
    const result = await this.api.get<HttpSuccessGetAll<RawCurrency>>(
      '/currencies?' + new URLSearchParams(queries).toString(),
      {},
      {
        headers: {
          authorization: `Bearer ${getToken()}`,
        },
      },
    );
    if (result.problem) throw new Error(result.problem);
    return result.data;
  }
  // update currency
  public async updateCurrency(id: string, data: Currency) {
    return this.api.patch<Currency, HttpError>('/currencies/' + id, data, {
      headers: {
        authorization: `Bearer ${getToken()}`,
      },
    });
  }
  // delete currency
  public async deleteCurrency(id: string) {
    return this.api.delete<RawCurrency, HttpError>(
      '/currencies/' + id,
      {},
      {
        headers: {
          authorization: `Bearer ${getToken()}`,
        },
      },
    );
  }
  // create currency
  public async createCurrency(data: Currency) {
    return this.api.post<RawCurrency, HttpError>('/currencies', data, {
      headers: {
        authorization: `Bearer ${getToken()}`,
      },
    });
  }
  // HTTP request to get all units
  public async getUnits(queries?: Record<string, string>) {
    const result = await this.api.get<HttpSuccessGetAll<RawUnit>>(
      '/units?' + new URLSearchParams(queries).toString(),
      {},
      {
        headers: {
          authorization: `Bearer ${getToken()}`,
        },
      },
    );
    if (result.problem) throw new Error(result.problem);
    return result.data;
  }
  // update unit
  public async updateUnit(id: string, data: Unit) {
    return this.api.patch<Unit, HttpError>('/units/' + id, data, {
      headers: {
        authorization: `Bearer ${getToken()}`,
      },
    });
  }
  // delete unit
  public async deleteUnit(id: string) {
    return this.api.delete<RawUnit, HttpError>(
      '/units/' + id,
      {},
      {
        headers: {
          authorization: `Bearer ${getToken()}`,
        },
      },
    );
  }
  // create unit
  public async createUnit(data: Unit) {
    return this.api.post<RawUnit, HttpError>('/units', data, {
      headers: {
        authorization: `Bearer ${getToken()}`,
      },
    });
  }

  // HTTP request to get all PropertyFields
  public async getPropertyFields(queries?: Record<string, string>) {
    const result = await this.api.get<HttpSuccessGetAll<RawPropertyField>>(
      '/fields?' + new URLSearchParams(queries).toString(),
      {},
      {
        headers: {
          authorization: `Bearer ${getToken()}`,
        },
      },
    );
    if (result.problem) throw new Error(result.problem);
    return result.data;
  }
  // update PropertyField
  public async updatePropertyField(id: string, data: PropertyField) {
    return this.api.patch<RawPropertyField, HttpError>('/fields/' + id, data, {
      headers: {
        authorization: `Bearer ${getToken()}`,
      },
    });
  }
  // delete PropertyField
  public async deletePropertyField(id: string) {
    return this.api.delete<RawPropertyField, HttpError>(
      '/fields/' + id,
      {},
      {
        headers: {
          authorization: `Bearer ${getToken()}`,
        },
      },
    );
  }

  // create PropertyField
  public async createPropertyField(data: PropertyField) {
    return this.api.post<RawPropertyField, HttpError>('/fields', data, {
      headers: {
        authorization: `Bearer ${getToken()}`,
      },
    });
  }

  // get all properties
  public async getProperties(queries?: Record<string, string>) {
    const result = await this.api.get<HttpSuccessGetAll<RawProperty>>(
      '/properties?' + new URLSearchParams(queries).toString(),
      {},
      {
        headers: {
          authorization: `Bearer ${getToken()}`,
        },
      },
    );
    if (result.problem) throw new Error(result.problem);
    return result.data;
  }
  // get property
  public async getProperty(id: string) {
    const result = await this.api.get<RawProperty>(
      '/properties/' + id,
      {},
      {
        headers: {
          authorization: `Bearer ${getToken()}`,
        },
      },
    );
    if (result.problem) throw new Error(result.problem);
    return result.data;
  }
  // update property
  public async updateProperty(id: string, data: Property) {
    return this.api.patch<RawProperty, HttpError>('/properties/' + id, data, {
      headers: {
        authorization: `Bearer ${getToken()}`,
      },
    });
  }
  // delete property
  public async deleteProperty(id: string) {
    return this.api.delete<RawProperty, HttpError>(
      '/properties/' + id,
      {},
      {
        headers: {
          authorization: `Bearer ${getToken()}`,
        },
      },
    );
  }
  // create property
  public async createProperty(data: Property) {
    return this.api.post<RawProperty, HttpError>('/properties', data, {
      headers: {
        authorization: `Bearer ${getToken()}`,
      },
    });
  }

  //Get pages
  public async getPages(queries?: Record<string, string>) {
    const result = await this.api.get<HttpSuccessGetAll<RawPage>>(
      '/pages?' + new URLSearchParams(queries).toString(),
      {},
      {
        headers: {
          authorization: `Bearer ${getToken()}`,
        },
      },
    );
    if (result.problem) throw new Error(result.problem);
    return result.data;
  }
  // get page
  public async getPage(id: string) {
    const result = await this.api.get<RawPage>(
      '/pages/' + id,
      {},
      {
        headers: {
          authorization: `Bearer ${getToken()}`,
        },
      },
    );
    if (result.problem) throw new Error(result.problem);
    return result.data;
  }
  // update page
  public async updatePage(id: string, data: Page) {
    return this.api.patch<RawPage, HttpError>('/pages/' + id, data, {
      headers: {
        authorization: `Bearer ${getToken()}`,
      },
    });
  }
  // create page
  public async createPage(data: Page) {
    return this.api.post<RawPage, HttpError>('/pages', data, {
      headers: {
        authorization: `Bearer ${getToken()}`,
      },
    });
  }
  // delete page
  public async deletePage(id: string) {
    return this.api.delete<RawPage, HttpError>(
      '/pages/' + id,
      {},
      {
        headers: {
          authorization: `Bearer ${getToken()}`,
        },
      },
    );
  }

  // HTTP request to get all types
  public async getTypes(queries?: Record<string, string>) {
    const result = await this.api.get<HttpSuccessGetAll<RawType>>(
      '/types?' + new URLSearchParams(queries).toString(),
      {},
      {
        headers: {
          authorization: `Bearer ${getToken()}`,
        },
      },
    );
    if (result.problem) throw new Error(result.problem);
    return result.data;
  }
  // update type
  public async updateType(id: string, data: Type) {
    return this.api.patch<Type, HttpError>('/types/' + id, data, {
      headers: {
        authorization: `Bearer ${getToken()}`,
      },
    });
  }
  // delete type
  public async deleteType(id: string) {
    return this.api.delete<RawType, HttpError>(
      '/types/' + id,
      {},
      {
        headers: {
          authorization: `Bearer ${getToken()}`,
        },
      },
    );
  }
  // create type
  public async createType(data: Type) {
    return this.api.post<RawType, HttpError>('/types', data, {
      headers: {
        authorization: `Bearer ${getToken()}`,
      },
    });
  }

  // Synchronized properties with leading
  public async syncLeading() {
    return this.api.post<RawType, HttpError>(
      '/sync-leading',
      {},
      {
        headers: {
          authorization: `Bearer ${getToken()}`,
        },
      },
    );
  }
}
const APIService = new API();

export default APIService;

import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import {
  InitialState,
  LoginErrorPayload,
  LoginPayload,
} from '../../interfaces/auth.interface';
import { User } from '@/interfaces/user.interface';

// Define the initial state using that type
const initialState: InitialState = {
  token: '',
  user: {
    _id: '',
    email: '',
    full_name: '',
    profile: '',
    rol_id: '',
  },
  rol: '',
  error: null,
  errorCode: undefined,
  loading: false,
};

export const authSlice = createSlice({
  name: 'auth',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    loginRequest: (state) => {
      state.loading = true;
    },
    loginSuccess: (state, action: PayloadAction<LoginPayload>) => {
      state.token = action.payload.token;
      state.user = action.payload.user;
      state.rol = action.payload.rol;
      state.loading = false;
      state.error = null;
      state.errorCode = undefined;
    },
    loginFailure: (state, action: PayloadAction<LoginErrorPayload>) => {
      state.token = '';
      state.rol = '';
      state.error = action.payload.error;
      state.errorCode = action.payload.errorCode;
      state.loading = false;
    },
    logout: (state) => {
      state.token = '';
      state.rol = '';
      state.user = initialState.user;
      state.loading = false;
    },
    updateUser: (state, action: PayloadAction<User>) => {
      state.user = action.payload;
    },
  },
});

export const { loginSuccess, loginFailure, loginRequest, logout, updateUser } =
  authSlice.actions;

// Other code such as selectors can use the imported `RootState` type
// export const selectAuth = (state: RootState) => state.auth;

export default authSlice.reducer;

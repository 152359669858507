import { configureStore } from '@reduxjs/toolkit';
import logger from 'redux-logger';

import {
  saveSessionMiddleware,
  SESSION_STORAGE_KEY,
} from './middlewares/session.middleware';
import reducer from './reducers';

/**
 * It gets the session from sessionStorage, parses it, and returns it
 * @returns an object with two properties: user and token.
 */
function getSessionAuth() {
  const session = sessionStorage.getItem(SESSION_STORAGE_KEY) || '';
  if (session) return JSON.parse(session);
  return {
    user: {},
    token: '',
  };
}
export const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(logger as any, saveSessionMiddleware),
  preloadedState: {
    auth: getSessionAuth(),
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

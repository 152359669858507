import { Middleware } from '@reduxjs/toolkit';

export const SESSION_STORAGE_KEY = 'auth';

/**
 * It takes the current state, calls the next middleware or reducer, and then saves the state to the
 * session storage
 * @param  - Middleware
 * @returns A function that takes a store and returns a function that takes a next and returns a
 * function that takes an action and returns the result of the next function.
 */
export const saveSessionMiddleware: Middleware =
  ({ getState }) =>
  (next) =>
  (action) => {
    // Llama al siguiente middleware o al reducer
    const result = next(action);
    // Guarda el estado en el session storage
    sessionStorage.setItem(SESSION_STORAGE_KEY, JSON.stringify(getState()?.auth));

    return result;
  };
